<template>
	<div class="mess-loading" role="alert" aria-busy="true">
		<span data-index="1" />
		<span data-index="2" />
		<span data-index="3" />
	</div>
</template>

<style lang="scss">
	.mess-loading {
		display: grid;
		grid-template-columns: 1fr 1fr;
		width: max-content;
		gap: 0.25em;
		margin: 0 auto;

		--color-1: currentColor;
		--color-2: currentColor;
		--color-3: currentColor;

		span {
			width: 1em;
			display: block;
			aspect-ratio: 1;

			--offset: calc(100% + 0.25em);
			--offset-inverted: calc(-100% - 0.25em);

			&[data-index='1'] {
				animation: loading 2s infinite;
				animation-delay: calc(2s / 16);
				background: var(--color-1);

				--x-1: 0;
				--y-1: 0;
				--x-2: var(--offset);
				--y-2: 0;
				--x-3: var(--offset);
				--y-3: var(--offset);
				--x-4: 0;
				--y-4: var(--offset);
			}

			&[data-index='2'] {
				animation: loading 2s infinite;
				background: var(--color-2);

				--x-1: 0;
				--y-1: 0;
				--x-2: 0;
				--y-2: var(--offset);
				--x-3: var(--offset-inverted);
				--y-3: var(--offset);
				--x-4: var(--offset-inverted);
				--y-4: 0;
			}
			&[data-index='3'] {
				animation: loading 2s infinite;
				animation-delay: calc(2s / 8);
				background: var(--color-3);

				--x-1: 0;
				--y-1: 0;
				--x-2: 0;
				--y-2: var(--offset-inverted);
				--x-3: var(--offset);
				--y-3: var(--offset-inverted);
				--x-4: var(--offset);
				--y-4: 0;
			}

			// &[data-index='1'],
			// &[data-index='3'] {
			// 	display: none;
			// }

			@keyframes loading {
				0% {
					transform: translate(var(--x-1), var(--y-1));
				}
				12.5% {
					transform: translate(var(--x-2), var(--y-2));
				}
				25% {
					transform: translate(var(--x-2), var(--y-2));
				}
				37.5% {
					transform: translate(var(--x-3), var(--y-3));
				}
				50% {
					transform: translate(var(--x-3), var(--y-3));
				}
				62.5% {
					transform: translate(var(--x-4), var(--y-4));
				}
				75% {
					transform: translate(var(--x-4), var(--y-4));
				}
				87.5% {
					transform: translate(var(--x-1), var(--y-1));
				}
				100% {
					transform: translate(var(--x-1), var(--y-1));
				}
			}
		}
	}
</style>
